<template>
  <div class="w_vedio_detail">
    <div class="l_desc">
      <div class="vedio_box">
        <template v-if="data.dto.course_video_list && data.dto.course_video_list.length > 0">
          <video id="videoPlay" :poster="_this.utils.HandleUrl(data.dto.img_url, 1)" controls="true"
            controlslist="nodownload" style="width:100%;height: 100%;object-fit:fill;">
            <source :src="data.dto.course_video_list[data.video_index].video_path" type="video/mp4">
          </video>
        </template>
      </div>
      <div class="vedio_title">{{ data.dto.title }}</div>
    </div>
    <div class="r_menu">
      <div class="menu_title">
        <div>课程选集</div>
      </div>
      <div class="menu_list">
        <div :title="item.title" :class="['item', data.video_index == index ? 'curr' : '']"
          v-for="(item, index) in data.dto.course_video_list" :key="index" @click="data.video_index = index">{{
  item.title
          }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { getCurrentInstance, reactive,watch,nextTick } from 'vue';
import { useRoute,useRouter } from 'vue-router';
import 'video.js/dist/video-js.css'

const _this = getCurrentInstance().appContext.config.globalProperties;
const $route = useRoute();
const $router = useRouter();

const data = reactive({
  dto: '',
  course_id: $route.params.id,
  video_index: $route.params.index,
});

watch(() => data.video_index,()=>{
  nextTick(() => {
    let temp_dom = document.getElementById('videoPlay');
    if (temp_dom) temp_dom.load();
  });
});

function GetCourseDetail() {
  var url = "/Open/GetCourseDetail";
  _this.$http.post(url, { id: data.course_id }).then(function (res) {
    if (res.data.status == 1) {
      data.dto = res.data.ds;
    }
  }.bind(this)).catch(function () {
  });
}

GetCourseDetail();

</script>